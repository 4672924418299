import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FaBug } from "react-icons/fa";
import { BsFillCloudMoonFill } from "react-icons/bs";

const Headercard = () => {
  return (
    <div>
      {/*Three card Started*/}
      <div className="three_box">
        {/*1st Card*/}
        <div className="boxes">
          <MdDesignServices />
          <h4>Creative Design</h4>
          <p>
            Compellingly promote collaborative products without synergistic
            schemas.
          </p>
        </div>

        {/*2nd Card*/}
        <div className="boxes_wapper">
          <div className="boxes">
            <FaBug />
            <h4>Cyber Security</h4>
            <p>
              Enthusiastically scale mission-critical imperatives rather than an
              expanded array.
            </p>
          </div>
          {/* <div className="hide_img"></div> */}
        </div>
        {/*3rd Card*/}
        <div className="boxes color">
          <BsFillCloudMoonFill />
          <h4 className="color">Cloud Services</h4>
          <p className="color">
            Rapidiously create cooperative resources rather than client-based
            leadership skills.
          </p>
        </div>
      </div>
      {/*Three card Ended*/}
    </div>
  );
};

export default Headercard;
