import React from "react";
import Navbar from "../../../Components/Navbar";
import Blogrightheader from "../../../Components/Blogrightheader";
import Detailsrightcontent from "../../../Components/Detailsrightcontent";
import Footer from "../../../Components/Footer";
import BottomTop from "../../../Components/BottomTop";

const Detailsright = () => {
  return (
    <>
      <Navbar />
      <Blogrightheader />
      <Detailsrightcontent />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Detailsright;
