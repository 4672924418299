import { useState, useEffect } from "react";
import axios from "axios";
import { BsSearch } from "react-icons/bs";

const Detailsrightcontent = () => {
  const [blog, setBlog] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/blog`;
    axios.get(url).then((res) => {
      setBlog(res.data);
    });
  }, []);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/news`;
    axios.get(url).then((res) => {
      setNews(res.data);
    });
  }, []);

  console.log("DATA", blog);
  console.log("DATA", news);
  return (
    <div className="blog_latest">
      <div className="blog_latest_wrapper_main">
        <div className="blog_details_wrapper">
          <div className="details_wrapper_banner"></div>
          <div className="details_wrapper_heading">
            <h2>Minimalist Chandelier</h2>
            <div className="details_wrapper_heading_date">
              <p>November 18, 2016</p>
              <p>
                In <span>Branding, Design</span>
              </p>
              <p>
                <span>3 Comments</span>
              </p>
            </div>
          </div>

          <div className="details_wrapper_pera">
            <p>
              Meh synth Schlitz, tempor duis single-origin coffee ea next level
              ethnic fingerstache fanny pack nostrud. Seitan High Life
              reprehenderit consectetur cupidatat kogi about me. Photo booth
              anim 8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse
              nihil, flexitarian Truffaut synth art party deep v chillwave.
              Seitan High Life reprehenderit consectetur cupidatat kogi.
            </p>
            <p>
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth et 8-bit kale chips proident chillwave deep v laborum.
              Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami
              readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean
              shorts fixie consequat flexitarian four loko.
            </p>
          </div>

          <div className="details_wrapper_quote">
            <i>
              To be yourself in a world that is constantly trying to make you
              something else is the greatest accomplishment.
            </i>
            <br />
            <i>
              <span className="be_strong">- Amanda Pollock, Google Inc.</span>
            </i>
          </div>

          <div className="details_wrapper_pera">
            <p>
              Meh synth Schlitz, tempor duis single-origin coffee ea next level
              ethnic fingerstache fanny pack nostrud. Seitan High Life
              reprehenderit consectetur cupidatat kogi about me. Photo booth
              anim 8-bit hella, PBR 3 wolf moon beard Helvetica.
            </p>
            <p>
              Meh synth Schlitz, tempor duis single-origin coffee ea next level
              ethnic fingerstache fanny pack nostrud. Seitan High Life
              reprehenderit consectetur cupidatat kogi about me. Photo booth
              anim 8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse
              nihil, flexitarian Truffaut synth art party deep v chillwave.
              Seitan High Life reprehenderit consectetur cupidatat kogi.
            </p>
          </div>

          <div className="details_wrapper_list">
            <ol>
              <li>Digital Strategy</li>
              <li>Software Development</li>
              <li>Interaction Design</li>
            </ol>
          </div>
          <div className="details_wrapper_pera">
            <p>
              Meh synth Schlitz, tempor duis single-origin coffee ea next level
              ethnic fingerstache fanny pack nostrud. Seitan High Life
              reprehenderit consectetur cupidatat kogi about me. Photo booth
              anim 8-bit hella, PBR 3 wolf moon beard Helvetica.
            </p>
            <p>
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth et 8-bit kale chips proident chillwave deep v laborum.
              Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami
              readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean
              shorts fixie consequat flexitarian four loko.
            </p>
          </div>

          <div className="details_wrapper_btn">
            <button>LIFESTYLE</button>
            <button>MUSIC</button>
            <button>NEWS</button>
            <button>TRAVEL</button>
          </div>

          <div className="details_wrapper_comment">
            <div className="details_wrapper_comment_heading">
              <h2>3 Comments</h2>
            </div>
            <div className="comment_box_wrapper">
              {blog &&
                blog.map((key) => {
                  return (
                    <div className="comment_box">
                      <div className="comment_box_img">
                        <img src={key.comment_img} />
                      </div>
                      <div className="comment_box_content">
                        <div className="comment_box_content_top">
                          <div className="comment_box_content_name">
                            <h3>{key.comment_name}</h3>
                            <span>{key.comment_date}</span>
                          </div>
                          <div className="comment_box_content_btn">
                            <button>Reply</button>
                          </div>
                        </div>
                        <div className="comment_box_bottom">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: key.comment_description,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="details_wrapper_form">
            <div className="details_wrapper_form_heading">
              <h3>Leave a Reply</h3>
              <p>
                Your email address will not be published. Required fields are
                marked
              </p>
            </div>

            <div className="form_top_input">
              <input type="text" placeholder="Name" />
              <input type="text" placeholder="Email" />
              <input type="text" placeholder="Website" />
            </div>

            <div className="form_bottom_input">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Comment"
              ></textarea>
            </div>

            <div className="form_bottom_btn">
              <button>Post Comment</button>
            </div>
          </div>
        </div>

        {/* **********This is Content part*********** */}
        <div className="blog_content_wrapper">
          <div className="blog_content_input">
            <input type="text" placeholder="Type Search Words" />
            <div className="search_icon">
              <BsSearch />
            </div>
          </div>
          <div className="blog_content_category">
            <h3>Categories</h3>
            <ul>
              <li>
                <span>Journey</span>
                <span>112</span>
              </li>
              <li>
                <span>Development</span>
                <span>86</span>
              </li>
              <li>
                <span>Sport</span>
                <span>10</span>
              </li>
              <li>
                <span>Photography</span>
                <span>144</span>
              </li>
              <li>
                <span>Symphony</span>
                <span>18</span>
              </li>
            </ul>
          </div>
          <div className="blog_content_recent">
            <h3>Recent Posts</h3>
            {/* post 1 */}
            <div className="recent_post_wrapper">
              {news &&
                news.map((key) => {
                  return (
                    <div className="recent_post">
                      <div className="recent_post_img">
                        <img src={key.banner_img} alt="" />
                      </div>
                      <div className="recent_post_title">
                        <p
                          className="recent_post_title_p"
                          dangerouslySetInnerHTML={{
                            __html: key.post_description.slice(0, 30),
                          }}
                        ></p>
                        <span className="recent_post_title_date">
                          {key.post_date}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="blog_content_newsletter">
            <h3>Newsletter</h3>
            <p>Enter your email address below to subscribe to my newsletter</p>
            <input type="text" placeholder="info@corporx.com" />
            <button>Subscribe</button>
          </div>

          <div className="blog_content_tag">
            <h3>Tags</h3>
            <div className="tag_buttons">
              <button>E-COMMERCE</button>
              <button>PORTFOLIO</button>
              <button>RESPONSIVE</button>
              <button>BOOTSTRAP</button>
              <button>BUSINESS</button>
              <button>CORPORATE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailsrightcontent;
