import React from "react";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoYoutube,
  BiLogoInstagramAlt,
} from "react-icons/bi";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_contact">
        <div className="footer_contact_left">
          <h1>Consulting Agency for Your Business</h1>
          <p>
            Rapidiously engage fully tested e-commerce with progressive
            architectures.
          </p>
        </div>
        <div className="footer_contact_right">
          <button>Contact With Us</button>
        </div>
      </div>

      <div className="footer_menu">
        {/* 1st box */}
        <div className="footer_menu_box_first">
          <ul>
            <li>
              <img src="./Images/logo.png" alt="" />
            </li>
            <li className="for_padding">
              Interactively unleash interactive best practices before
              technically sound portals.
            </li>
            <li>
              <div className="footer_icon">
                <div className="twitter">
                  <BiLogoTwitter />
                </div>
                <div className="facebook">
                  <BiLogoFacebook />
                </div>
                <div className="youtube">
                  <BiLogoYoutube />
                </div>
                <div className="instagram">
                  <BiLogoInstagramAlt />
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* 2nd box */}
        <div className="footer_menu_box">
          <ul>
            <li className="head_padding">
              <h3>Company</h3>
            </li>

            <li className="menu_padding">About Us</li>
            <li className="menu_padding">Careers</li>
            <li className="menu_padding">Customers</li>
            <li className="menu_padding">Community</li>
          </ul>
        </div>

        {/* 3rd box */}
        <div className="footer_menu_box">
          <ul>
            <li className="head_padding">
              <h3>Resources</h3>
            </li>

            <li className="menu_padding">Help</li>
            <li className="menu_padding">Events</li>
            <li className="menu_padding">Live Sessions</li>
            <li className="menu_padding">Open sources</li>
          </ul>
        </div>

        {/* 4th box */}
        <div className="footer_menu_box">
          <ul>
            <li className="head_padding">
              <h3>Support</h3>
            </li>

            <li className="menu_padding">Help</li>
            <li className="menu_padding">Tech Support</li>
            <li className="menu_padding">Network Status</li>
            <li className="menu_padding">Contact Support</li>
          </ul>
        </div>
      </div>

      <div className="copyright">
        <span>Copyrights © 2023. All rights reserved By Insignia</span>
      </div>
    </div>
  );
};

export default Footer;
