import React from "react";
import Servicesheader from "../../Components/Servicesheader";
import Navbar from "../../Components/Navbar";
import Servicesoffer from "../../Components/Servicesoffer";
import Aboutwork from "../../Components/Aboutwork";
import Servicesdownload from "../../Components/Servicesdownload";
import Services from "../../Components/Services";
import Latest from "../../Components/Latest";
import Footer from "../../Components/Footer";
import BottomTop from "../../Components/BottomTop";
import Reviewslider from "../../Components/Reviewslider2";

const Servicespage = () => {
  return (
    <>
      <Navbar />
      <Servicesheader />
      <Servicesoffer />
      <Aboutwork />
      <Servicesdownload />
      <Services />
      <Reviewslider />
      <Latest />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Servicespage;
