import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoYoutube,
  BiLogoInstagramAlt,
} from "react-icons/bi";

const Team = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/team`;
    axios.get(url).then((res) => {
      setTeam(res.data);
    });
  }, []);

  console.log("DATA", team);
  return (
    <div className="team">
      <div className="team_heading">
        <h1>Our Team</h1>
        <p>
          Dynamically pursue reliable convergence rather than 24/7 process
          improvements develop end-to-end customer service.
        </p>
      </div>

      <div className="team_cards_wapper">
        {/* **********This part for Dynamic Data********** */}
        {team &&
          team.map((key) => {
            return (
              <div className="team_cards">
                <div className="team_cards_img">
                  <img src={key.emp_img} alt="" />
                </div>
                <div className="team_cards_heading">
                  <h3>{key.emp_name}</h3>
                  <p>{key.emp_designation}</p>
                </div>
                <div className="team_cards_icons">
                  <BiLogoFacebook />
                  <BiLogoTwitter />
                  <BiLogoYoutube />
                  <BiLogoInstagramAlt />
                </div>
                <div className="team_cards_pera">
                  <p
                    dangerouslySetInnerHTML={{ __html: key.emp_description }}
                  ></p>
                </div>
              </div>
            );
          })}

        {/* **********This part for Dynamic Data********** */}
      </div>
    </div>
  );
};

export default Team;
