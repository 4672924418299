import React from "react";
import Projectheader from "../../Components/Projectheader";
import Navbar from "../../Components/Navbar";
import Work from "../../Components/Work";
import Footer from "../../Components/Footer";
import BottomTop from "../../Components/BottomTop";

const Project = () => {
  return (
    <>
      <Navbar />
      <Projectheader />
      <Work />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Project;
