import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aboutpage from "./Pages/AboutUS/Aboutpage";
import Pages from "./Pages/Pages/Pages";
import Servicespage from "./Pages/Services/Servicespage";
import Project from "./Pages/Project/Project";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/ContactUS/Contact";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/About" element={<Aboutpage />}></Route>
          <Route path="/Pages" element={<Pages />}></Route>
          <Route path="/Services" element={<Servicespage />}></Route>
          <Route path="/Project" element={<Project />}></Route>
          <Route path="/Blog" element={<Blog />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
