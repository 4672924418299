import React from "react";
import { Link } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";

const Blogrightheader = () => {
  return (
    <div className="blog_right_header">
      <div className="blog_right_bg">
        <div className="blog_right_contact">
          <h1>Blog</h1>
          <div className="blog_right_contact_menu">
            <Link className="onpage_links" to="/">
              Home
            </Link>
            <BiSolidChevronRight />
            <Link className="onpage_links" to="/Blog">
              Blog
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogrightheader;
