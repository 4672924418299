import React from "react";
import { Link } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";

const AboutHeader = () => {
  return (
    <div className="aboutheader">
      <div className="aboutheader_bg">
        <div className="aboutheader_contact">
          <h1>About Us</h1>
          <div className="aboutheader_contact_menu">
            <Link className="onpage_links" to="/">
              Home
            </Link>
            <BiSolidChevronRight />
            <Link className="onpage_links" to="/services">
              About Us
            </Link>
          </div>
          {/* <span>Home  Pages services Us</span> */}
        </div>
      </div>
    </div>
  );
};

export default AboutHeader;
