import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaCalendarAlt } from "react-icons/fa";

const Latest = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/news`;
    axios.get(url).then((res) => {
      setNews(res.data);
    });
  }, []);

  console.log("DATA", news);

  return (
    <div className="latest">
      <div className="latest_heading">
        <h1>Our Latest News</h1>
        <p>
          Dynamically pursue reliable convergence rather than 24/7 process
          improvements develop end-to-end customer service.
        </p>
      </div>

      {/* card main */}
      <div className="card_wapper">
        {/* **********This part for Dynamic Data********** */}
        {news &&
          news.map((key) => {
            return (
              <div className="card_main">
                <div className="card_main_top">
                  <span>{key.news_type}</span>
                  <img src={key.banner_img} alt="" />
                </div>
                <div className="card_main_bottom">
                  <div className="card_main_bottom_date">
                    <div className="card_main_bottom_span">
                      <img src={key.writter_img} alt="" />
                      <span>{key.writter_name}</span>
                    </div>
                    <div className="latest_date_box">
                      <span>
                        <FaCalendarAlt /> {key.post_date}
                      </span>
                    </div>
                  </div>
                  <h3>{key.post_heading}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: key.post_description }}
                  ></p>
                  <a href="#">Read More</a>
                </div>
              </div>
            );
          })}

        {/* **********This part for Dynamic Data********** */}

        {/* this is 2nd card */}
        {/* <div className="card_main">
          <div className="card_main_top">
            <span>Marketing</span>
            <img
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
              alt=""
            />
          </div>
          <div className="card_main_bottom">
            <div className="card_main_bottom_date">
              <div className="card_main_bottom_span">
                <img
                  src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80"
                  alt=""
                />
                <span>Writer</span>
              </div>
              <div className="latest_date_box">
                <span>
                  <FaCalendarAlt /> 15 March 2020
                </span>
              </div>
            </div>
            <h3>Authoritatively unleash fully</h3>
            <p>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <a href="#">Read More</a>
          </div>
        </div> */}

        {/* this is 3rd card */}
        {/* <div className="card_main">
          <div className="card_main_top">
            <span>Business</span>
            <img
              src="https://images.unsplash.com/photo-1619597455322-4fbbd820250a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
              alt=""
            />
          </div>
          <div className="card_main_bottom">
            <div className="card_main_bottom_date">
              <div className="card_main_bottom_span">
                <img
                  src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=461&q=80"
                  alt=""
                />
                <span>Jeo B</span>
              </div>
              <div className="latest_date_box">
                <span>
                  <FaCalendarAlt /> 15 March 2020
                </span>
              </div>
            </div>
            <h3>We partnered up with Google</h3>
            <p>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <a href="#">Read More</a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Latest;
