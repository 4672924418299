import React, { useState, useEffect } from "react";
import axios from "axios";

const Page2 = () => {
  const [feature, setFeature] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/feature`;
    axios.get(url).then((res) => {
      setFeature(res.data);
    });
  }, []);

  console.log("DATA", feature);
  return (
    <div className="aboutlinks_boxes" id="features">
      {feature &&
        feature.map((key) => {
          return (
            <div className="aboutlinks_sub_box">
              <img src={key.feature_img} alt="" />
              <h3>{key.feature_heading}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: key.feature_description }}
              ></p>
            </div>
          );
        })}
    </div>
  );
};

export default Page2;
