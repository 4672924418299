import React from "react";
import { BsApple, BsWindows } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa";

const Servicesdownload = () => {
  return (
    <div className="servicesdown">
      <div className="bg_video">
        <video src="./video/forbg2.mp4" loop autoPlay controls></video>
      </div>
      <div className="servicesdown_sub">
        <div className="servicesdown_heading">
          <h2>Download Apps Managing Business</h2>
        </div>
        <div className="servicesdown_pera">
          <p>
            Building your Apps helps attract more potential clients. Our
            integrated marketing team will work high-impact convergence.
          </p>
        </div>
        <div className="servicesdown_option_div">
          {/* this is 1st box */}
          <div className="servicesdown_option">
            <div className="servicesdown_option_left">
              <BsApple />
            </div>
            <div className="servicesdown_option_right">
              <span>Download on the</span>
              <p>App Store</p>
            </div>
          </div>
          {/* this is 2nd box */}
          <div className="servicesdown_option">
            <div className="servicesdown_option_left">
              <FaGooglePlay />
            </div>
            <div className="servicesdown_option_right">
              <span>Download on the</span>
              <p>Google Play</p>
            </div>
          </div>

          {/* this is 3rd box */}
          <div className="servicesdown_option">
            <div className="servicesdown_option_left">
              <BsWindows />
            </div>
            <div className="servicesdown_option_right">
              <span>Download on the</span>
              <p>Windows</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicesdownload;
