import React from "react";
import { BsFastForward } from "react-icons/bs";
import { FaArrowRight, FaFire, FaLightbulb, FaShapes } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";

const Servicesoffer = () => {
  return (
    <div className="servicesoffer">
      <div className="servicesoffer_left">
        <div className="services_content">
          <h2>World Class Service Growth Your Business</h2>
          <p>
            Convert your emails into tickets and keep them all organized in one
            place. Never leave customers' questions unanswered.
          </p>
          <ul>
            <li>
              <span>
                <BsFastForward />
              </span>
              <strong> Creative</strong> Websites Design
            </li>
            <li>
              <span>
                <BsFastForward />
              </span>
              <strong> Accounting</strong> Procedures Guidebook
            </li>
            <li>
              <span>
                <BsFastForward />
              </span>
              <strong> Cost</strong> Accounting Fundamentals
            </li>
            <li>
              <span>
                <BsFastForward />
              </span>
              <strong> SEO</strong> Optimization Services
            </li>
          </ul>
          <button>
            View More
            <FaArrowRight />
          </button>
        </div>
      </div>
      <div className="servicesoffer_right">
        <div className="servicesoffer_right_boxes_left">
          <div className="box_left_top">
            <span>
              <RiCustomerService2Fill />
            </span>
            <h3>Customer Service</h3>
            <p>Create low level customer service experiences.</p>
          </div>
          <div className="box_left_bottom">
            <span>
              <FaFire />
            </span>
            <h3>Predictive service</h3>
            <p>Proactively develop B2B alignments rather.</p>
          </div>
        </div>
        <div className="servicesoffer_right_boxes_right">
          <div className="box_right_top">
            <span>
              <FaLightbulb />
            </span>
            <h3>Service to sales</h3>
            <p>Progressively brand clicks-and-mortar.</p>
          </div>
          <div className="box_right_bottom">
            <span>
              <FaShapes />
            </span>
            <h3>Make it simple</h3>
            <p>Competently evolve user friendly technology.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicesoffer;
