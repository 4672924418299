import React from "react";

const Header = () => {
  return (
    <div className="main_header">
      <div className="main_banner">
        {/*Content Started*/}

        <div className="main_content">
          <p className="main_content-first-p">BUSINESS SOLUTIONS</p>
          <h1>Your Most Trusted Business Partner</h1>
          <p className="main_content-second-p">
            Holisticly procrastinate mission-critical convergence with reliable
            customer service. Assertively underwhelm idea-sharing for impactful
            solutions.
          </p>
          <button>Get Start Now</button>
        </div>
      </div>
      {/*Content Ended*/}
    </div>
  );
};

export default Header;
