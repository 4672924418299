import React from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";

const Navbar = () => {
  const [show, setShow] = useState(true);
  function menuShow() {
    setShow(!show);
    if (show == true) {
      let menu = document.getElementById("burgermenu");
      menu.style.marginTop = "0px";
    } else {
      let submenu = document.getElementById("burgermenu");
      submenu.style.marginTop = "-600px";
    }
  }
  console.log(show);

  return (
    <div className="navbar_main">
      {/*Navbar Started*/}
      <nav className="navbar">
        <div className="logo">
          <img src="./Images/logo.png" alt="logo" />
        </div>
        <div className="menu">
          <ul>
            <li>
              <Link className="menu_option" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="menu_option" to="/About">
                About Us
              </Link>
            </li>

            <li>
              <Link className="menu_option" to="/Services">
                Services
              </Link>
            </li>

            <li>
              <Link className="menu_option" to="/Project">
                Project
              </Link>
            </li>
            <li>
              <Link className="menu_option" to="/Blog">
                Blog
              </Link>
            </li>
            <li>
              <Link className="menu_option" to="/Contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="humburger" onClick={menuShow}>
          <RxHamburgerMenu />
        </div>

        {/* Burger Menu */}

        <div className="burgermenu" id="burgermenu">
          <div className="color_logo">
            <img src="./Images/colorlogo.png" alt="logo" />
          </div>
          <div className="burgermenu_list">
            <ul>
              <li>
                <Link className="burger_menu_option" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="burger_menu_option" to="/About">
                  About Us
                </Link>
              </li>

              <li>
                <Link className="burger_menu_option" to="/Services">
                  Services
                </Link>
              </li>

              <li>
                <Link className="burger_menu_option" to="/Project">
                  Project
                </Link>
              </li>
              <li>
                <Link className="burger_menu_option" to="/Blog">
                  Blog
                </Link>
              </li>
              <li>
                <Link className="burger_menu_option" to="/Contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/*Navbar Ended*/}
    </div>
  );
};

export default Navbar;
