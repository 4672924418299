import React from "react";
import { BsFastForward } from "react-icons/bs";

const Page1 = () => {
  return (
    <div className="about" id="about">
      <div className="about_img">
        <img src="./Images/color-shape.svg" alt="" className="svg_img" />
        <div className="about_img_border">
          <img src="./Images/about-us-2.jpg" alt="" />
        </div>
        <img src="./Images/dot-shape.png" alt="" className="dot_img" />
      </div>
      <div className="about_content">
        <h2>Total Solutions for Your Business Here</h2>
        <p>
          Interactively develop timely niche markets before extensive
          imperatives. Professionally repurpose strategies.
        </p>
        <ul>
          <li>
            <span>
              <BsFastForward />
            </span>
            <strong> Creative</strong> Websites Design
          </li>
          <li>
            <span>
              <BsFastForward />
            </span>
            <strong> Accounting</strong> Procedures Guidebook
          </li>
          <li>
            <span>
              <BsFastForward />
            </span>
            <strong> Cost</strong> Accounting Fundamentals
          </li>
          <li>
            <span>
              <BsFastForward />
            </span>
            <strong> SEO</strong> Optimization Services
          </li>
        </ul>
        <button>View Services</button>
      </div>
    </div>
  );
};

export default Page1;
