import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { FaQuoteLeft } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import axios from "axios";

export default function Reviewslider() {
  const [review, setReview] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/review`;
    axios.get(url).then((res) => {
      setReview(res.data);
    });
  }, []);

  console.log("DATA", review);
  return (
    <div className="review">
      <div className="review_heading">
        <h1>What Clients Say About Us</h1>
        <p>
          Rapidiously morph transparent internal or "organic" sources whereas
          resource sucking e-business innovate compelling internal.
        </p>
      </div>
      <Swiper
        className="mySwiper swiper-h"
        spaceBetween={50}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {review &&
          review.map((key) => {
            return (
              <SwiperSlide>
                {/* 1st box */}
                <div class="review_box_slider">
                  <div className="review_box_icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="review_box_pera">
                    <p
                      dangerouslySetInnerHTML={{ __html: key.review_pera }}
                    ></p>
                  </div>

                  <div className="review_box_bottom">
                    <div className="review_box_bottom_left">
                      <div className="left_image">
                        <img src={key.review_img} />
                      </div>
                      <div className="left_name">
                        <h5>{key.review_writter}</h5>
                        <span>{key.review_position}</span>
                      </div>
                    </div>
                    <div className="review_box_bottom_right">
                      <div className="right_star">
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                      </div>
                      <div className="right_num">
                        <span>{key.review_rating} Out of 5</span>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

        {/* <SwiperSlide>
          <Swiper
            className="mySwiper2 swiper-v"
            direction={"vertical"}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>Vertical Slide 1</SwiperSlide>
            <SwiperSlide>Vertical Slide 2</SwiperSlide>
            <SwiperSlide>Vertical Slide 3</SwiperSlide>
            <SwiperSlide>Vertical Slide 4</SwiperSlide>
            <SwiperSlide>Vertical Slide 5</SwiperSlide>
          </Swiper>
        </SwiperSlide> */}
        {/* <SwiperSlide> */}
        {/* 2nd box */}
        {/* <div class="review_box">
            <div className="review_box_icon">
              <FaQuoteLeft />
            </div>
            <div className="review_box_pera">
              <p>
                Intrinsicly facilitate functional imperatives without
                next-generation services. Compellingly revolutionize worldwide
                users enterprise best practices.
              </p>
            </div>

            <div className="review_box_bottom">
              <div className="review_box_bottom_left">
                <div className="left_image">
                  <img src="https://images.unsplash.com/flagged/photo-1573603867003-89f5fd7a7576?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YnVzaW5lc3MlMjBtYW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60" />
                </div>
                <div className="left_name">
                  <h5>Pirtle Karol</h5>
                  <span>Team Leader, ThemeTags</span>
                </div>
              </div>
              <div className="review_box_bottom_right">
                <div className="right_star">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                </div>
                <div className="right_num">
                  <span>5.0 Out of 5</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide> */}
        {/* 3rd box */}
        {/* <div class="review_box">
            <div className="review_box_icon">
              <FaQuoteLeft />
            </div>
            <div className="review_box_pera">
              <p>
                Intrinsicly facilitate functional imperatives without
                next-generation services. Compellingly revolutionize worldwide
                users enterprise best practices.
              </p>
            </div>

            <div className="review_box_bottom">
              <div className="review_box_bottom_left">
                <div className="left_image">
                  <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60" />
                </div>
                <div className="left_name">
                  <h5>Janny thomas</h5>
                  <span>CTO, ThemeTags</span>
                </div>
              </div>
              <div className="review_box_bottom_right">
                <div className="right_star">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                </div>
                <div className="right_num">
                  <span>5.0 Out of 5</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide> */}
        {/* 4th box */}
        {/* <div class="review_box">
            <div className="review_box_icon">
              <FaQuoteLeft />
            </div>
            <div className="review_box_pera">
              <p>
                Intrinsicly facilitate functional imperatives without
                next-generation services. Compellingly revolutionize worldwide
                users enterprise best practices.
              </p>
            </div>

            <div className="review_box_bottom">
              <div className="review_box_bottom_left">
                <div className="left_image">
                  <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGF2YXRhcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60" />
                </div>
                <div className="left_name">
                  <h5>Karol Kia</h5>
                  <span>CFO, ThemeTags</span>
                </div>
              </div>
              <div className="review_box_bottom_right">
                <div className="right_star">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                </div>
                <div className="right_num">
                  <span>5.0 Out of 5</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
}
