import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPhone, BsHeadset } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";

const Contactoption = () => {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/contact`;
    axios.get(url).then((res) => {
      setContact(res.data);
    });
  }, []);

  console.log("DATA", contact);
  return (
    <div className="contactoption">
      {contact &&
        contact.map((key) => {
          return (
            <>
              <div className="contactoption_box">
                <div className="box_icons">
                  <BsPhone />
                </div>
                <h3>Call Us</h3>
                <span>+{key.contact_num}</span>
              </div>
              <div className="contactoption_box">
                <div className="box_icons">
                  <IoLocationOutline />
                </div>
                <h3>Visit Us</h3>
                <span
                  dangerouslySetInnerHTML={{ __html: key.contact_shortadd }}
                ></span>
              </div>
              <div className="contactoption_box">
                <div className="box_icons">
                  <AiOutlineMail />
                </div>
                <h3>Mail Us</h3>
                <span>{key.contact_mail}</span>
              </div>
              <div className="contactoption_box">
                <div className="box_icons">
                  <BsHeadset />
                </div>
                <h3>Live Chat</h3>
                <span>
                  {key.contact_time_from} To {key.contact_time_to}
                </span>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default Contactoption;
