import React, { useEffect, useState } from "react";
import axios from "axios";

const Page3 = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/service`;
    axios.get(url).then((res) => {
      setServices(res.data);
    });
  }, []);

  console.log("DATA", services);
  return (
    <>
      <div className="services_boxes">
        {/* **********This part for Dynamic Data********** */}
        {services &&
          services.map((key) => {
            return (
              <div className="services_boxes_sub">
                <div className="services_icons">
                  <img src={key.img} alt="" />
                </div>
                <div className="services_content">
                  <h5>{key.services_title}</h5>
                  <p>{key.services_description}</p>
                  <a href="#">Read more</a>
                </div>
              </div>
            );
          })}
        {/* **********This part for Dynamic Data********** */}
      </div>
    </>
  );
};

export default Page3;
