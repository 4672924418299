import React from "react";
import { useState } from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

const Aboutlinks = () => {
  const [active, setActive] = useState("Second");

  return (
    <div className="aboutlinks">
      <div className="aboutlinks_heading">
        <h1>About Us</h1>
        <p>
          Distinctively grow go forward manufactured products and optimal
          networks. Enthusiastically disseminate user-centric outsourcing
          revolutionary
        </p>
      </div>

      <div className="aboutlinks_option">
        <ul>
          <li
            className="aboutlinks_menu_option"
            onClick={() => setActive("First")}
          >
            About Us
          </li>
          <li
            className="aboutlinks_menu_option"
            onClick={() => setActive("Second")}
          >
            Our Features
          </li>
          <li
            className="aboutlinks_menu_option"
            onClick={() => setActive("Third")}
          >
            Our Services
          </li>
        </ul>
      </div>
      <div className="links_main_wrapper">
        <div>
          {active === "First" && <Page1 title="1" />}
          {active === "Second" && <Page2 title="2" />}
          {active === "Third" && <Page3 title="3" />}
        </div>
      </div>
    </div>
  );
};

export default Aboutlinks;
