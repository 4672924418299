import React from "react";
// import Blogright from "./Blog_Right/Blogright";
import Detailsright from "./Details_Right/Detailsright";

const Blog = () => {
  return (
    <>
      {/* <Blogright /> */}
      <Detailsright />
    </>
  );
};

export default Blog;
