import React from "react";
import { BsApple, BsWindows } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa";

const Download = () => {
  return (
    <div className="download">
      <div className="download_sub">
        <div className="download_heading">
          <h2>Download Our Business Apps</h2>
        </div>
        <div className="download_pera">
          <p>
            Building your Apps helps attract more potential clients. Our
            integrated marketing team will promote enabled internal or work
            high-impact convergence.
          </p>
        </div>
        <div className="download_option_div">
          {/* this is 1st box */}
          <div className="download_option">
            <div className="download_option_left">
              <BsApple />
            </div>
            <div className="download_option_right">
              <span>Download on the</span>
              <p>App Store</p>
            </div>
          </div>
          {/* this is 2nd box */}
          <div className="download_option">
            <div className="download_option_left">
              <FaGooglePlay />
            </div>
            <div className="download_option_right">
              <span>Download on the</span>
              <p>Google Play</p>
            </div>
          </div>

          {/* this is 3rd box */}
          <div className="download_option">
            <div className="download_option_left">
              <BsWindows />
            </div>
            <div className="download_option_right">
              <span>Download on the</span>
              <p>Windows</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
