import React from "react";
import Navbar from "../../Components/Navbar";
import Contactoption from "../../Components/Contactoption";
import Contactform from "../../Components/Contactform";
import Contactmap from "../../Components/Contactmap";
import Footer from "../../Components/Footer";
import Contactheader from "../../Components/Contactheader";
import BottomTop from "../../Components/BottomTop";

const Contact = () => {
  return (
    <>
      <Navbar />
      <Contactheader />
      <Contactoption />
      <Contactform />
      <Contactmap />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Contact;
