import React, { useEffect, useState } from "react";
import axios from "axios";

const Work = () => {
  const [work, setWork] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/work`;
    axios.get(url).then((res) => {
      setWork(res.data);
    });
  }, []);

  console.log("DATA", work);

  function all() {
    // document.getElementById("b1").style.display = "block";
    // document.getElementById("b2").style.display = "block";
    // document.getElementById("b3").style.display = "block";
    // document.getElementById("b4").style.display = "block";
    // document.getElementById("b5").style.display = "block";
    // document.getElementById("b6").style.display = "block";
  }

  function branding() {
    // document.getElementById("b1").style.display = "block";
    // document.getElementById("b2").style.display = "none";
    // document.getElementById("b3").style.display = "none";
    // document.getElementById("b4").style.display = "block";
    // document.getElementById("b5").style.display = "none";
    // document.getElementById("b6").style.display = "block";
  }

  function animation() {
    // document.getElementById("b1").style.display = "none";
    // document.getElementById("b2").style.display = "block";
    // document.getElementById("b3").style.display = "block";
    // document.getElementById("b4").style.display = "none";
    // document.getElementById("b5").style.display = "block";
    // document.getElementById("b6").style.display = "none";
  }

  function others() {
    // document.getElementById("b1").style.display = "none";
    // document.getElementById("b2").style.display = "block";
    // document.getElementById("b3").style.display = "none";
    // document.getElementById("b4").style.display = "none";
    // document.getElementById("b5").style.display = "block";
    // document.getElementById("b6").style.display = "none";
  }

  return (
    <div className="work">
      <div className="work_heading">
        <h1>Check Our Quality Work</h1>
        <p>
          Dynamically pursue reliable convergence rather than 24/7 process
          improvements develop end-to-end customer service.
        </p>
      </div>

      <div className="work_btn">
        <div className="work_btn_div" id="all" onClick={all}>
          <span>All</span>
        </div>
        <div className="work_btn_div" id="branding" onClick={branding}>
          <span>Branding</span>
        </div>
        <div className="work_btn_div" id="animation" onClick={animation}>
          <span>Animation</span>
        </div>
        <div className="work_btn_div" id="others" onClick={others}>
          <span>Others</span>
        </div>
      </div>

      <div className="work_box_main">
        {work &&
          work.map((key) => {
            return (
              <div class="flip-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img
                      src={key.background}
                      alt="Avatar"
                      style={{ width: "300px", height: "300px;" }}
                    />
                  </div>
                  <div class="flip-card-back">
                    <h3>{key.work_heading}</h3>
                    <p>{key.work_subheading}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Work;
