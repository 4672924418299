import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoYoutube,
  BiLogoInstagramAlt,
} from "react-icons/bi";

const Aboutteam = () => {
  const [aboutteam, setAboutteam] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/aboutteam`;
    axios.get(url).then((res) => {
      setAboutteam(res.data);
    });
  }, []);

  console.log("DATA", aboutteam);
  return (
    <div className="aboutteam">
      <div className="aboutteam_heading">
        <h1>Meet our lovely team</h1>
        <p>
          Dynamically pursue reliable convergence rather than 24/7 process
          improvements develop end-to-end customer service.
        </p>
      </div>

      <div className="aboutteam_boxes">
        {aboutteam &&
          aboutteam.map((key) => {
            return (
              <div className="aboutteam_sub_box">
                <div className="aboutteam_sub_box_img">
                  <img src={key.aboutteam_img} alt="" />
                </div>
                <h5>{key.aboutteam_name}</h5>
                <span>{key.aboutteam_position}</span>

                <div className="aboutteam_icons">
                  <div className="aboutteam_facebook">
                    <BiLogoFacebook />
                  </div>
                  <div className="aboutteam_twitter">
                    <BiLogoTwitter />
                  </div>
                  <div className="aboutteam_youtube">
                    <BiLogoYoutube />
                  </div>
                  <div className="aboutteam_instagram">
                    <BiLogoInstagramAlt />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Aboutteam;
