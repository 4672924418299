import React from "react";
import Navbar from "../../Components/Navbar";
import Header from "../../Components/Header";
import Headercard from "../../Components/Headercard";
import About from "../../Components/About";
import Services from "../../Components/Services";
import Download from "../../Components/Download";
import Work from "../../Components/Work";
import Latest from "../../Components/Latest";
import Team from "../../Components/Team";
import Review from "../../Components/Review";
import Footer from "../../Components/Footer";
import BottomTop from "../../Components/BottomTop";

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Headercard />
      <About />
      <Services />
      <Download />
      <Work />
      <Latest />
      <Team />
      <Review />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Home;
