import React from "react";
import { Link } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";

const Contactheader = () => {
  return (
    <div className="contact_header">
      <div className="contact_header_bg">
        <div className="contact_header_contact">
          <h1>Contact US</h1>
          <div className="contact_header_contact_menu">
            <Link className="onpage_links" to="/">
              Home
            </Link>
            <BiSolidChevronRight />
            <Link className="onpage_links" to="/services">
              Contact Us
            </Link>
          </div>
          {/* <span>Home  Pages services Us</span> */}
        </div>
      </div>
    </div>
  );
};

export default Contactheader;
