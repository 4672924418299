import { AiOutlineArrowRight } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Contactform = () => {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/contact`;
    axios.get(url).then((res) => {
      setContact(res.data);
    });
  }, []);

  console.log("DATA", contact);
  return (
    <div className="contactform">
      {contact &&
        contact.map((key) => {
          return (
            <>
              <div className="contactform_form">
                <div className="form_cover">
                  <h2>Ready to get started?</h2>
                  <input type="text" placeholder="Enter Name" />
                  <input type="email" name="" id="" placeholder="Enter Email" />
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder="Message"
                  ></textarea>
                  <button type="submit">Send Message</button>
                </div>
              </div>
              <div className="contactform_content">
                <div className="from_content_details">
                  <h2>Looking for a excellent Business idea?</h2>
                  <p>
                    Seamlessly deliver pandemic e-services and next-generation
                    initiatives.
                  </p>
                  <button>
                    Get Directions <AiOutlineArrowRight />
                  </button>
                </div>
                <div className="from_content_address">
                  <h3>Our Headquarters</h3>
                  <p
                    className="from_content_address_p1"
                    dangerouslySetInnerHTML={{ __html: key.contact_add }}
                  ></p>
                  <p className="from_content_address_p2">
                    Phone: +{key.contact_num} <br />
                    Email: <span> {key.contact_mail}</span>
                  </p>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default Contactform;
