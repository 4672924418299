import React from "react";
import Navbar from "../../Components/Navbar";
import AboutHeader from "../../Components/Aboutheader";
import Aboutwork from "../../Components/Aboutwork";
import Aboutlinks from "../../Components/About_Links/Aboutlinks";
import Download from "../../Components/Download";
import Aboutteam from "../../Components/Aboutteam";
import Review from "../../Components/Review";
import Latest from "../../Components/Latest";
import Logoslider from "../../Components/Logoslider";
import Footer from "../../Components/Footer";
import BottomTop from "../../Components/BottomTop";

const Aboutpage = () => {
  return (
    <>
      <Navbar />
      <AboutHeader />
      <Aboutwork />
      <Aboutlinks />
      <Download />
      <Aboutteam />
      <Review />
      <Latest />
      <Logoslider />
      <Footer />
      <BottomTop />
    </>
  );
};

export default Aboutpage;
