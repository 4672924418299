import React, { useEffect, useState } from "react";
import axios from "axios";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/service`;
    axios.get(url).then((res) => {
      setServices(res.data);
    });
  }, []);

  console.log("DATA", services);

  return (
    <div className="services">
      <div className="services_heading">
        <h2>We Provide Quality Services</h2>
      </div>
      <div className="services_pera">
        <p>
          Efficiently aggregate end-to-end core competencies without
          maintainable. Dynamically foster tactical solutions without enabled
          value.
        </p>
      </div>

      <div className="services_boxes">
        {/* **********This part for Dynamic Data********** */}
        {services &&
          services.map((key) => {
            return (
              <div className="services_boxes_sub">
                <div className="services_icons">
                  <img src={key.img} alt="" />
                </div>
                <div className="services_content">
                  <h5>{key.services_title}</h5>
                  <p>{key.services_description}</p>
                  <a href="#">Read more</a>
                </div>
              </div>
            );
          })}
        {/* **********This part for Dynamic Data********** */}
      </div>
    </div>
  );
};

export default Services;
