import React from "react";
import { Link } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";

const Projectheader = () => {
  return (
    <div className="projectheader">
      <div className="projectheader_bg">
        <div className="projectheader_contact">
          <h1>Projects</h1>
          <div className="projectheader_contact_menu">
            <Link className="onpage_links" to="/">
              Home
            </Link>
            <BiSolidChevronRight />
            <Link className="onpage_links" to="/Project">
              Projects
            </Link>
          </div>
          {/* <span>Home  Pages services Us</span> */}
        </div>
      </div>
    </div>
  );
};

export default Projectheader;
