import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const Aboutwork = () => {
  const [aboutwork, setAboutwork] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/aboutwork`;
    axios.get(url).then((res) => {
      setAboutwork(res.data);
    });
  }, []);

  console.log("DATA", aboutwork);
  return (
    <div className="aboutwork">
      <div className="aboutwork_heading">
        <h1>How Does it Work?</h1>
        <p>
          Distinctively grow go forward manufactured products and
          enthusiastically disseminate outsourcing customer service.
        </p>
      </div>

      <div className="aboutwork_boxes">
        {aboutwork &&
          aboutwork.map((key) => {
            return (
              <div className="aboutwork_sub_box">
                <div className="icon_outer_three">
                  <img src={key.about_img} alt="" />
                </div>
                <h3>{key.about_heading}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: key.about_description }}
                ></p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Aboutwork;
