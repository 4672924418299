import React from "react";
import { LuArrowUpSquare } from "react-icons/lu";
import { useState, useEffect } from "react";

const BottomTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <LuArrowUpSquare
        className="bottom_top"
        style={{ display: isVisible ? "block" : "none" }}
        onClick={goTop}
      />
    </div>
  );
};

export default BottomTop;
