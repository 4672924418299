import React from "react";

const Logoslider = () => {
  return (
    <div class="main">
      <div class="sub">
        <div class="img">
          <img src="./Images/logo1.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo2.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo3.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo4.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo1.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo5.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo6.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo7.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo1.png" alt="" />
        </div>
        <div class="img">
          <img src="./Images/logo2.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Logoslider;
