import React, { useEffect, useState } from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";

const Review = () => {
  const [review, setReview] = useState([]);

  useEffect(() => {
    let url = `http://localhost/wp-corporx/wp-json/custom/v1/review`;
    axios.get(url).then((res) => {
      setReview(res.data);
    });
  }, []);

  console.log("DATA", review);
  return (
    <div className="main-review">
      <div className="main-review_heading">
        <h1>What Clients Say About Us</h1>
        <p>
          Rapidiously morph transparent internal or "organic" sources whereas
          resource sucking e-business innovate compelling internal.
        </p>
      </div>

      <div className="review_box_wapper">
        {review &&
          review.map((key) => {
            return (
              <div class="review_box">
                <div className="review_box_icon">
                  <FaQuoteLeft />
                </div>
                <div className="review_box_pera">
                  <p dangerouslySetInnerHTML={{ __html: key.review_pera }}></p>
                </div>

                <div className="review_box_bottom">
                  <div className="review_box_bottom_left">
                    <div className="left_image">
                      <img src={key.review_img} />
                    </div>
                    <div className="left_name">
                      <h5>{key.review_writter}</h5>
                      <span>{key.review_position}</span>
                    </div>
                  </div>
                  <div className="review_box_bottom_right">
                    <div className="right_star">
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                    </div>
                    <div className="right_num">
                      <span>
                        <span>{key.review_rating}</span> Out of 5
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Review;
